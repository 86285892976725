// Imports
// --------------------------------------------------
@import "resources";
// --------------------------------------------------
* {
    outline:none !important;
}

// INPUT TYPE NUMBER ARROW REMOVAL
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

// clearfix
.cf:before, .cf:after {
    content: " ";
    display: table;
}
.cf:after, .cf:before {
    clear: both;
}
.cf {
    *zoom: 1;
}

// placeholder
::-webkit-input-placeholder {
    color: #999;
}
:-moz-placeholder {
    color: #999;
}
::-moz-placeholder {
    color: #999;
}
:-ms-input-placeholder {
    color: #999;
}
// DEFAULTS
label.required::after {
    display:none !important;
}
strong {
    font-weight:500;
}
.strike, strike {
    text-decoration:line-through;
}
body {
    font-weight:$font-weight-light;
}
a {
    transition:$global_transition;
    color:$c-btn-green;

    &:hover {
        color:$c-btn-green-hover;
    }
}
p {
    &:last-child {
        margin-bottom:0;
    }
}
.btn {
    border:none;
    border-radius:$global_border_radius;
    transition:$global_transition;
    font-weight:$font-weight-normal;
    color:#fff;
    padding:0.8125rem 3rem;
    text-transform: none;
    text-decoration:none;
    line-height:1;
    text-align:center;

    &:hover {
        background:$black;
        color:#fff;
    }
    &.btn-primary {
        background:$c-btn-green;
        border:1px solid $c-btn-green;

        &:hover {
            border-color:$c-btn-green-hover;
            background:$c-btn-green-hover;
        }
    }
    &.btn-outline-primary {
        background:$white;
        border:1px solid $black;
        color:$black;

        &:hover {
            background:$black;
            color:$white;
        }
    }
    &.btn-primary-inverted, &.btn-link {
        border:1px solid $c-btn-green;
        color:$c-btn-green;
        background:#fff;

        &:hover {
            background:$c-btn-green;
            color:#fff;
        }
    }
    &.btn-secondary {
        background:$black;

        &:hover {
            background:$c-btn-red-hover;
        }
    }
    &.btn-link {
        text-decoration:none;
    }
}
.modal-dialog {
    .modal-header {
        background:$c-silver-lighter;
    }
}
//PAGINATION
.pagination {
    display:block;
    text-align:right;

    .page-link, .item {
        border-radius:$global_border_radius;
        padding:0.5em 1em;
        text-decoration:none;
        border:1px solid $c-silver-light;
        color:$black;
        display:inline-block;
    }
    .item, .page-item {
        margin-right:0.25em;
        display:inline-block;

        &:last-child {
            margin-right:0;
        }
    }
    .page-item {
        &.active .page-link, &.active:hover .page-link {
            background-color:$c-btn-green;
            color:$white;
            border-color:$c-btn-green;
        }
        &.disabled {
            opacity:0.5;
        }
        &:hover .page-link {
            background:$c-silver-light;
        }
    }
    .item {
        &.active, &.active:hover {
            background-color:$c-btn-green;
            color:$white;
        }
        &.disabled {
            opacity:0.5;
        }
        &:hover {
            background:$c-silver-light;
        }
    }
}
.stickers-wrap, .stickers-bottom-wrap {
    position:absolute;
    z-index:5;
    width:100%;
    top:0;
    left:0;
    text-transform: lowercase;
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    .sticker {
        border-radius:$global_border_radius;
        line-height:1;
        display:block;
        float:left;
        padding:0.5em 0.875em;
        font-size:0.625em;
        clear:left;
    }
    .discount-content {
        font-weight:$font-weight-bold;

        .sticker {
            clear:none;
        }
        .per, .num {
            background:$c-red;
            color:#fff;
        }
        .per {
            margin-right:0.25em;
        }
    }
    .stickers-content {
        .sticker {
            font-weight:$font-weight-normal;
            margin-bottom:0.25em;

            &:last-child {
                margin-bottom:0;
            }
            &.sale {
                background:$c-red;
                color:$white;
            }
            &.new {
                background:$c-orange;
                color:$c-orange-dark;
            }
            &.recommended {
                clear:left;
                background:$c-green;
                color:$white;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        flex-wrap:wrap;

        .stickers-content {
            width:100%;
            order:2;

        }
        .discount-content {
            width:100%;
            order:1;
            flex-wrap:nowrap;
            margin-bottom:0.2em;
        }
    }
}
.stickers-bottom-wrap {
    top:auto;
    bottom:0;
    justify-content: flex-end;

    .sticker {
        background:$primary;
        color:#fff;
        font-weight:$font-weight-normal;
        margin-right:0.25em;
        clear:none;
        max-width:120px;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;

        &:last-child {
            margin-right:0;
        }
    }
}
.heading-wrap {
    margin-bottom:2.5em;
}
h1, h2, h3, h4, h5, h6 {
    font-weight:$font-weight-extrabold;
    margin-bottom:0.5em;
    line-height:normal;
    font-family:$font-family-header;
    overflow-wrap: break-word;

    &.ico-header {
        span {
            display:inline-block;
            padding-left:1.4em;
            position:relative;

            svg {
                position:absolute;
                left:0;
                top:0;
                bottom:0;
                margin:auto;
                width:0.875em;
                height:0.875em;
                fill:$c-green;
            }
        }
    }
    &.normal {
        font-weight:$font-weight-base;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom:0.25em;
    }
    a {
        text-decoration:none;
    }
}
// HEADER - layout/header/shop_header.html.twig
.header-wrap {
    padding-top:1.25em;
    padding-bottom:1.25em;
    background:$c-black;

    .logo-wrap {

        .logo-link {
            display:inline-block;
        }
        img {
            display:block;
            width:100%;
            max-width:9.375em;
            height:auto;
        }
    }
    .menu-show-wrap {
        display:none;
    }
    .reg-btn {
        margin-left:1em;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
    @include media-breakpoint-down(md) {
        .menu-show-wrap {
            display:block;
        }
        .reg-btn {
            display:none;
        }
        .logo-wrap {
            position:absolute;
            left:0;
            right:0;
            margin:auto;
            z-index:10;
            width:7.5em;

            .logo-content {
                text-align:center;
            }
        }
        .header-buttons {
            order:1;
            align-items:stretch;
        }
        .search-box-wrap {
            order:3;
        }
        .search-box-wrap {
            margin-top:0.5em;
        }
    }
    @media (max-width:576px) {
        .logo-wrap {
            right:auto;
            margin:0;
            left:60px;
        }
    }
}
// SEARCH BOX - layout/header/_searchBox.html.twig
.search-box-wrap {
    flex-direction: column;
    justify-content: center;
    display:flex;
    position:relative;
    z-index:200;

    .search-box-input-wrap {
        position:relative;

        input {
            background:$white;
            border-radius:$global_border_radius;
            height:3.125em;
            padding-right:3.125em;
            display:block;
            width:100%;
            border:none;
            position:relative;
            z-index:5;
            padding-left:1.5em;
        }
        .svg-wrap {
            z-index:10;
            position:absolute;
            width:3.125em;
            height:3.125em;
            right:0;
            top:0;

            svg {
                width:1.5em;
                height:1.5em;
                position:absolute;
                left:0;
                right:0;
                top:0;
                bottom:0;
                fill:#d1d5cd;
                margin:auto;
                display:block;
            }
        }
    }
}

// LOGIN BOX - layout/header/login.html.twig
.login-wrap {
    .login-content {
        @extend .d-flex, .justify-content-end, .flex-row;
    }
    .login {
        position:relative;

        .login-form {
            input {
                margin-bottom:0.5rem;
            }
            &:last-child {
                margin-bottom:0;
            }
        }
    }
    .modal {
        margin:0 auto;
    }
    .modal-outer-wrap {
        height:100%;
        padding:1em;
        display:flex;
        flex-direction: column;
        justify-content: center;
        position:relative;
        z-index:9999;
    }
    .login-modal-wrap {
        max-width:360px;

        input, .btn {
            padding:0.8em 1em;
            height:auto;
        }
        .login-link {
            font-weight:$font-weight-medium;
        }
        .form-group {
            margin-bottom:0;
        }
        p {
            margin-bottom:0.25em;
        }
        .modal-footer {
            justify-content: center;
            text-align:center;
        }
    }
}
// CART - layout/header/_cart.html.twig
.cart-wrap {
    .cart-content {
        @extend .d-flex, .justify-content-end, .flex-row;

        @include media-breakpoint-down(sm) {
            justify-content: flex-end !important;
        }
    }
    @include media-breakpoint-down(md) {
        flex-grow:0;
    }
}

// HEADER-BTN - layout/header/ - shop_header.html.twig / _login.html.twig / _cart.html.twig / _last_viewed.html.twig
.header-buttons {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.header-btn {
    display:block;
    width:3.4375em;
    height:3.4375em;
    position:relative;
    border-radius:3.4375em;
    border:0 none;
    background:none;


    &.btn-responsive {
        display:none;
    }
    @include media-breakpoint-down(xs) {
        &.btn-responsive {
            display:block;
        }
        &.btn-non-responsive {
            display:none;
        }
    }
    svg {
        position:absolute;
        fill:$white;
        left:0;
        top:0;
        bottom:0;
        right:0;
        margin:auto;
        width:1.75em;
        height:1.75em;
        transition:$global_transition;

        @include media-breakpoint-down (md) {
            width:1.5em;
            height:1.5em;
        }
    }

    &:hover {
        svg {
            fill:$c-green;
        }
    }
    .badge {
        position:absolute;
        font-size:0.625em;
        font-weight:$font-weight-extrabold;
        bottom:0;
        right:0;
        display:block;
        border-radius:1em;
        min-width:1.75em;
        height:1.75em;
        padding-left:0.3125em;
        padding-right:0.3125em;
        line-height:1.45em;
        color:#fff;
        background:$black;
    }
    @include media-breakpoint-down(sm) {
        font-size:1em;
    }
}
// USER DROPDOWN
.user-dropdown-wrap {
    padding:0;

    .header-btn.logged-user:not(:hover) {
        svg {
            fill:$c-btn-green-hover;
        }
    }

    .dropdown-header {
        font-size:0.875em;
        font-weight:$font-weight-medium;
        padding-bottom:0.5em;
        border-bottom:1px solid #e9ecef;
        padding-left:0.5em;
        padding-right:0.5em;
    }
    .dropdown-list-wrap {
        margin:0;
        padding:0;
        list-style: none;
    }
    .dropdown-menu {
        padding:0;
    }
    .dropdown-item-wrap {
        list-style:none;
        padding:0;
        margin:0;

        &.logout-wrap, &.back-to-shop-wrap {
            border-top:1px solid #e9ecef;
            padding:0.25em;
        }
    }
    .dropdown-item {
        padding:0.5em 0.5em 0.5em 2.5em;
        position:relative;
        font-size:0.875em;

        svg {
            fill:$c-black;
            position:absolute;
            left:0.5em;
            top:0;
            bottom:0;
            margin:auto;
            width:1em;
            height:1em;
            display:block;
        }
        &.logout {
            background:$c-red;
            padding:1em;
            text-align:center;

            &:hover {
                background:$c-red-dark;
            }
        }
        &.back-to-shop {
            background:$c-orange;
            padding:1em;
            text-align:center;

            &:hover {
                background:$c-orange-dark;
            }
        }
    }
}
// ACCOUNT MENU - account/menu/_content.html.twig
.account-menu-wrap {
    .navbar-nav {
        flex:1;
    }
    .nav-item {
        flex:1;

        .nav-link {
            text-align:center;
        }
    }
}
// MAIN MENU - layout/menu/_main_menu.html.twig
.main-menu-wrap {
    background:$c-silver;
    font-family: $font-family-header;
    font-weight: 500;
    position:relative;
    z-index:100;

    ul {
        list-style:none;
        margin:0;
        padding:0;
    }
    .main-menu-content {
        margin:0;
        padding:0;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        justify-content: center;
        position:relative;

        .item-wrap {
            padding-left:1.25em;
            padding-right:1.25em;

            .item {
                position:relative;
                padding:1.4em;
                padding-left:3.25em;
                display:block;
                color:$black;
                line-height:1;
                text-decoration:none;

                .ico-wrap {
                    width:2.25em;
                    height:2.25em;
                    position:absolute;
                    background:#fff;
                    border-radius:5em;
                    left:0;
                    top:0;
                    bottom:0;
                    margin:auto;

                    svg {
                        position:absolute;
                        width:1.25em;
                        height:1.25em;
                        margin:auto;
                        left:0;
                        top:0;
                        bottom:0;
                        right:0;
                        fill:$c-btn-green;
                    }
                }
            }
            &.active {
                .item {
                    color:$c-green;

                    .ico-wrap {
                        background-color:$c-green;

                        svg {
                            fill:$white;
                        }
                    }
                }
            }
            &:hover {
                .menu-child {
                    display:block;
                }
            }
        }
        .menu-child {
            position:absolute;
            left:0;
            top:100%;
            width:100%;
            text-align:center;
            padding:0.25em 0;
            display:none;
            background:$black;

            .item-wrap {
                display:inline-block;
                margin:0.25em 0.125em;
                padding:0;

                .item {
                    padding:0.5em;
                    font-weight:$font-weight-normal;
                    border:1px solid $white;
                    border-radius:$global_border_radius;
                    color:$white;
                }
                &:hover, &.active {
                    .item {
                        background:$white;
                        color:$black;
                    }
                }
            }
            .menu-child {
                display:none !important;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .main-menu-content {
            .item-wrap {
                padding-left:0;
                padding-right:0;
            }
        }
    }
    @include media-breakpoint-down(md) {
        position:fixed;
        top:0;
        width:100%;
        left:-280px;
        max-width:280px;
        height:100%;
        overflow:hidden;
        overflow-y:auto;
        transition:$global_transition;
        z-index:400;

        &.active {
            left:0;
        }
        .main-menu-overlay {
            display:none;
            position:fixed;
            background:rgba(0,0,0,0.5);
            left:0;
            top:0;
            bottom:0;
            right:0;
        }
        .main-menu {
            background:$c-silver;
            width:100%;
            padding:1em 0;
            position:relative;
            z-index:10;
            min-height:100%;
        }
        .main-menu-content {
            .item-wrap {
                padding-left:1.5em;
                padding-right:1.5em;
                width:100%;
            }
            .menu-child {
                display:block;
                position:static;
                background:none;

                .item-wrap {
                    display:block;
                    text-align:left;

                    .item {
                        display:inline-block;
                        color:$black;
                        border-color:$black;
                    }
                    .menu-child {
                        display:block !important;

                        .item-wrap {
                            margin-left:10px;
                        }
                    }
                    &:hover {
                        .item {

                        }
                        .menu-child {
                            .item-wrap {
                                .item {
                                    background:transparent;
                                    color:$black;
                                }

                                &:hover {
                                    .item {
                                        background:$white;
                                        color:$black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// SUBCATEGORIES - product/index/_topBar.html.twig
.subcategories-wrap {
    @include media-breakpoint-down(md) {
        display:none !important;
    }
    &.level-1 {
        background:#e6e6e6;
        border-bottom: 1px solid $c-silver-light;
    }
    &.level-2 {
        background:#efefef;
        border-bottom: 1px solid $c-silver-light;
    }
}
#subcategories-list {
    margin:0;
    text-align:center;
    padding:0.25em 0;
    display:block;

    .list-item-wrap {
        display:inline-block;
        margin:0.25em 0.125em;

        .list-item {
            padding:0.5em;
            border:1px solid $c-black;
            border-radius:$global_border_radius;
            color:$c-black;
            text-decoration:none;
            display:block;
            line-height:1;

            &.active {
                color: $white;
                background: $c-black;
            }
        }
        &:hover {
            .list-item {
                background:$c-black;
                color:$white;
            }
        }
    }
}
// SECTION HEADER
.section-header-wrap {
    padding-top:3em;
    padding-bottom:3em;
    margin-bottom:3.75em;
    border-bottom:1px solid $c-silver-light;
    // /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fafafa+0,f0f0f0+100 */
    background: #fafafa; /* Old browsers */
    background: -moz-linear-gradient(top,  #fafafa 0%, #f0f0f0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #fafafa 0%,#f0f0f0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #fafafa 0%,#f0f0f0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#f0f0f0',GradientType=0 ); /* IE6-9 */
    position:relative;
    overflow:hidden;

    .section-header-left-bg {
        position:absolute;
        z-index:5;
        left:0;
        bottom:0;
        width:15.625em;
        height:auto;
        display:block;

        @media (max-width:1640px) {
            left:-150px;
            bottom:-50px;
        }
    }
    .section-header-right-bg {
        position:absolute;
        z-index:5;
        right:0;
        top:0;
        width:15.625em;
        height:auto;
        display:block;

        @media (max-width:1640px) {
            right:-170px
        }
    }
    .section-header {
        position:relative;
        z-index:10;
    }
    .section-header-image {
        img {
            display:block;
            width:100%;
            height:auto;
            border-radius:$global_border_radius;
        }
    }
}
// BREADCRUMBS - taxon/_breadcrumb.html.twig
.breadcrumbs-wrap {
    padding-top:0.5em;
    padding-bottom:0.5em;
    font-size:0.875em;
    border-radius:$global_border_radius;
    border-bottom:1px solid $c-silver-light;

    .breadcrumbs-content {
        margin:0;
        padding:0;
        list-style:none;
        font-size:1em;
    }
    .item-wrap {
        display:inline;
        position:relative;
        margin-right:1.6em;

        svg {
            fill:$black;
            transition:$global_transition;
        }
        &:after {
            content:'»';
            position:absolute;
            left:100%;
            margin-left:0.8em;
        }
        &.active {
            font-weight:$font-weight-medium;
            color:$c-green;
        }
        &:last-child {
            margin-right:0;

            &:after {
                display:none;
            }
        }
        .item {
            color:$black;
            text-decoration:underline;

            &:hover {
                text-decoration:none;

                svg {
                    fill:$c-green;
                }
            }
        }
    }
}
// MAIN CONTAINER - layout.html.twig
.main-container {
    padding-top:2rem;
    min-height: 400px;
}

// COUNT - default
.count-wrap {
    min-width:4.875rem;

    .count {
        display:block;
        float:left;
        height:1.875rem;
        width:1rem;
        text-align:center;
        position:relative;
        border-radius:$global_border_radius;
        text-decoration:none;
        background:$c-btn-green;
        border:1px solid $c-btn-green;

        &:hover {
            background:$c-btn-green-hover;
            border-color:$c-btn-green-hover;

            svg {
                fill:$white;
            }
        }
        svg {
            fill:$white;
            position:absolute;
            left:0;
            top:0;
            right:0;
            bottom:0;
            margin:auto;
            width:0.375rem;
            height:0.375rem;
            transition:$global_transition;
        }
        &.plus {
            margin-right:0.5em;
        }
    }
    input {
        margin-right:0.25rem;
        margin-left:0.25rem;
        text-align:center;
        font-size:0.875em;
        display:block;
        float:left;
        width:1.875rem;
        height:1.875rem;
        border:1px solid $c-light-silver;
        background:#fff;
        transition:$global_transition;
        border-radius:$global_border_radius;
        padding:0;
    }

    &.big {
        display:flex;
        flex-direction: row;

        .count {
            margin-top:0.75em;
        }
        input {
            font-size:1.25em;
            height:3.125rem;
            width:3.125rem;
        }
        .btn {
            height:3.125rem;
            font-size:1.25em;
            flex:1;
        }
    }
}
// CART-POPUP - cart/widget/_popup.html.twig
.cart-modal-wrap {
    padding-top:0.5em;
    padding-bottom:0.5em;
    font-size:0.75em;

    table {
        width:100%;
        margin-bottom:1em;

        tr {
            td {
                padding-top:0.5em;
                padding-bottom:0.5em;
            }
        }
        tr:nth-child(even) {
            td {
                background:$c-silver-lighter;
            }
        }
        tr:nth-last-child(2) {
            td {
                padding-bottom:0.5em;
            }
        }
        tr:last-child {
            td {
                padding-top:0.5em;
                border-top:1px solid $c-silver-light;
            }
        }
        .name {
            font-weight:$font-weight-normal;
            padding-left:0.5rem;
        }
        .price {
            text-align:right;
            color:$c-green;
            font-weight:$font-weight-extrabold;
            padding-right:0.5rem
        }
        .sum {
            padding-left:0.5em;
            padding-right:0.5em;
            white-space: nowrap;
            text-align:right;
        }
        .num {
            text-align:center;
            display:block;
            white-space: nowrap;
        }
        .text {
            font-weight:$font-weight-extrabold;
            padding-left:0.5rem
        }
        .summary {
            text-align:right;
            color:$c-green;
            font-weight:$font-weight-extrabold;
            padding-right:0.5rem;
        }
    }
    .btn {
        font-size:1em;
        padding-top:0.5em;
        padding-bottom:0.5em;
    }
    .edit-cart {
        margin-bottom:0.5em;
        text-transform:none;
    }
}
// PRODUCT CARD - product/box/_content.html.twig
.product-card-wrap {
    margin-bottom:1.75rem;
    padding-top:1em;
    padding-bottom:1em;
    background:#fff;
    position:relative;

    .product-card {
        height:100%;
        position:relative;
        z-index:5;
        padding-left:1em;
        padding-right:1em;
    }
    .product-card-content {
        display:flex;
        flex-direction: column;
        height:100%;
    }
    .product-image-wrap {
        position:relative;
        display:block;
        margin-bottom:1.25em;

        img {
            display:block;
            width:100%;
            height:auto;
        }
    }
    .product-name {
        font-weight:$font-weight-normal;
        margin-bottom:0.5em;
        line-height:normal;

        a {
            text-decoration:none;
            display:block;
            color:$primary;

            &:hover {
                color:$c-green;
            }
        }
    }
    .product-description {
        font-size:0.875em;
        margin-bottom:1.5em;
        line-height:1.7em;
    }

    .product-card-bottom {
        margin-top:auto;

        del {
            font-size:0.875em;
            color:$c-silver-dark;
            margin-right:1em;
        }
    }
    .price-wrap {
        span {
            font-weight:$font-weight-bold;
            color:$c-green;
        }
        strike, del {
            color:$c-silver-dark;
            text-decoration: line-through;
            font-size:1em;
            margin-right:0.5em;
        }
        .price-basic {
            display:flex;
            flex-direction: column;
            justify-content:center;
            &.price-small {
                del, strike {
                    display:block;
                    font-size:0.75em;
                    margin:0;
                    line-height:1;
                }
                .price {
                    line-height:1;
                    display:block;
                    margin-top:0.25em;
                    font-size:0.875em;
                }
            }
        }
        .price-premium {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            position:relative;

            h6 {
                font-size:0.625rem;
                text-transform: uppercase;
            }
            .price {
                display:inline-block;
                border:1px solid $c-orange;
                color:$c-orange;
                padding:0.5em 0.75em;
                line-height:1;
                border-radius:$global-border_radius;
            }
            .price-description {
                color:$c-orange-dark;
                position:absolute;
                bottom:100%;
                right:15px;
                margin-top:-5px;
                font-size:10px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .product-card {
            padding-left:0;
            padding-right:0;
        }
    }
}

// PRODUCT CARD INLINE -
.product-card-inline-wrap {
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $c-light-silver;

    &:before {
        display: none;
    }

    .product-card-inline-content {
        display: flex;
        flex-direction: row;

        .product-part {
            padding-right: 0;
            display:flex;
            flex-direction: column;

            .product-part-bottom {
                display: flex;
                flex-direction: row;
                margin-top:auto;
            }
        }
    }
    .product-image-wrap {
        margin-bottom: 0;
        width: 3.75em;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .stickers-wrap {
            display: none;
        }
    }

    .product-name {
        margin-bottom: 0.2em;
        font-weight:500;

        a {
            color:$black;

            &:hover {
                color:$c-btn-green;
            }
        }
    }
    .price-wrap {
        margin-bottom: 0;

        .price-top {
            text-align: left;

            span {
                font-size: 1em;
                font-weight:500;
                color:$c-green;
            }
        }
        .price-dph {
            margin-right: 1em;
        }
    }

    .add-to-cart-wrap {
        padding-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .count-wrap {
            .count.plus {
                margin-right:0.5em;
            }
        }
        .btn {
            display:block;
            float:left;
            font-weight:$font-weight-normal;
            padding:0 1.25em;
            height:1.875rem;
            line-height:1.75em;
            white-space:nowrap;
            position:relative;
            border:1px solid $c-btn-green;

            &:hover {
                color:$c-btn-green;
                background:$white;

                svg {
                    fill:$c-btn-green;
                }
            }

            .text {
                text-transform: none;
                font-size:0.875em;
            }
            svg {
                width:1em;
                height:1em;
                position:absolute;
                left:0;
                right:0;
                top:0;
                bottom:0;
                margin:auto;
                display:none;
                fill:#fff;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .product-card-inline-content {
            .product-part {
                .product-part-bottom {
                    flex-wrap: wrap;
                }
            }
        }
        .price-wrap, .add-to-cart-wrap {
            width: 100%;
        }
        .add-to-cart-wrap {
            padding-left: 0;
            margin-top: 0.5em;
            align-items: flex-start;
        }
    }
}
// CTA HORIZONTAL - layout/_cta_horizontal.html.twig
.cta-horizontal-wrap {
    margin-top:5rem;

    .cta-horizontal-content {
        position:relative;
        background:$c-black;
        display:flex;
        flex-direction: column;
        padding-top:4rem;
        padding-bottom:4rem;

        .triangle {
            display:block;
            position:absolute;
            left:100%;
            margin-left:-2px;
            fill:$c-black;
            right:0;
            top:0;
            width:auto;
            height:100%;
        }
        * {
            color:#fff;
        }
        .cta-text {
            position:relative;
            z-index:10;
        }
        .btn {
            position:absolute;
            left:100%;
            transform:translateX(-25%);
            margin-top:0.9em;
            z-index:10;
            border:0.5em solid $white;
        }
        .cta-img-wrap {
            position:absolute;
            height:100%;
            width:37.5em;
            overflow: hidden;
            right:0;
            top:0;
            bottom:0;
            z-index:5;
        }
        .cta-img {
            fill:$c-green-black;
            height:20rem;
            width:auto;
            display:block;
            right:3rem;
            bottom:-2rem;
            position:absolute;
            transform:rotate(15deg);
            z-index:5;
        }
    }
    @include media-breakpoint-down(lg) {
        .cta-horizontal-content {
            display:block;
            text-align:center;

            .triangle {
                display:none;
            }
            .cta-text {
                position:relative;
                z-index:10;
            }
            .btn {
                position:relative;
                z-index:10;
                transform:none;
                margin-top:2em;
                display:inline-block;
                left:auto;
                padding:1em;
            }
        }
    }
}
// LATEST ARTICLES - blog/_latest.html.twig
.latest-articles-wrap {
    margin-top:5rem;
    .latest-articles-top {
        .row {
            margin-left:-2em;
            margin-right:-2em;
        }
        .item {
            .image {
                padding-right:2em;
                padding-left:2em;
                margin-bottom:0;
            }
        }
    }
    .item {
        padding-left:2em;
        padding-right:2em;

        .image {
            margin-bottom:1em;
        }
        img {
            display:block;
            width:100%;
            border-radius: $global_border_radius;
        }
        h3 {
            font-weight:500;
            line-height:normal;
            margin-bottom:0.5em;
            a {
                color:$c-black;

                &:hover {
                    color:$c-green;
                }
            }
        }
    }
    .latest-articles-bottom {
        margin-top:5rem;
    }
    @include media-breakpoint-down(md) {
        .item {
            margin-top:2rem;
            padding-left:15px;
            padding-right:15px;

            &:last-child {
                margin-bottom:0;
            }
        }
        .latest-articles-top {
            .row {
                margin-left:-15px;
                margin-right:-15px;
            }
            .item {
                .image {
                    padding-left:15px;
                    padding-right:15px;
                    margin-bottom:1em;
                    margin-bottom:2em;
                }
            }
        }
        .latest-articles-bottom {
            margin-top:0;
        }
    }
}
// FOOTER - layout/footer/_footer.html.twig
.footer-wrap {
    margin-top:5rem;
    background:$c-black;
    color:#fff;
    padding-top:3.75rem;
    padding-bottom:3.75rem;
    overflow:hidden;

    .footer {
        position:relative;
    }
    * {
        color:#fff;
    }
    .box {
        position:relative;
        z-index:10;
    }
    ul {
        margin:0;
        padding:0;
        list-style:none;
    }
    .left-part {
        li {
            padding-left:1em;
            position:relative;

            &:before {
                position:absolute;
                left:0;
                content:'+';
            }
        }
    }
    .right-part {
        text-align:right;
        display:flex;
        flex-direction:column;
        align-items: flex-end;
        justify-content: center;

        .btn {
            font-weight:$font-weight-medium;
        }
        .social-wrap {
            margin-top:1em;

            ul {
                display:block;
                text-align:right;

                li {
                    display:inline-block;
                    margin-right:1em;

                    &:last-child {
                        margin-right:0;
                    }
                    a {
                        display:block;
                        width:2em;
                        height:2em;
                        position:relative;

                        &:hover {
                            svg {
                                fill:$white;
                            }
                        }
                    }
                    svg {
                        fill:$c-green-light;
                        display:block;
                        width:1.5em;
                        height:1.5em;
                        position:absolute;
                        left:0;
                        top:0;
                        bottom:0;
                        right:0;
                        margin:auto;
                        transition:$global_transition;
                    }
                }
            }
        }
    }
    .footer-img {
        fill:$c-green-black;
        width:40%;
        height:auto;
        display:block;
        right:-5rem;
        top:0;
        bottom:0;
        margin:auto;
        position:absolute;
        transform:rotate(15deg);
    }
    .payment-img {
        width: 180px;
        height: auto;
        margin-top: 0.5em;
    }
}
@include media-breakpoint-down(md) {
    body {
        font-size:0.875em;
    }
}

.mt--10 {
    margin-top: -10px !important;
}


.container-phone{
    display: none;
}

@media (max-width: 768px) {
    .container-phone{
        display: block;

        img {
            display: block;
            width: 100%;
        }
    }
}

.container-pc{
    display: none;
}

@media (min-width: 769px) {
    .container-pc{
        display: block;

        img {
            display: block;
            width: 100%;
        }
    }
}

.cookieconsent button {
    color: $c-btn-green;
    background: white;
    border: none;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 15px;
    font-weight: bold;
}

.cookieconsent {
    position:fixed;
    padding:20px;
    left:0;
    bottom:0;
    background-color:#000;
    color:#FFF;
    text-align:center;
    width:100%;
    z-index:99999;
}

.cookieconsent button:hover {
    background: $c-btn-green;
    color: white;
    border: none;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 15px;
    font-weight: bold;
}
