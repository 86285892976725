.col-0-1qtr, .col-1-1qtr, .col-2-1qtr, .col-3-1qtr, .col-4-1qtr, .col-5-1qtr, .col-6-1qtr, .col-7-1qtr, .col-8-1qtr, .col-9-1qtr, .col-10-1qtr, .col-11-1qtr,
.col-0-half, .col-1-half, .col-2-half, .col-3-half, .col-4-half, .col-5-half, .col-6-half, .col-7-half, .col-8-half, .col-9-half, .col-10-half, .col-11-half,
.col-0-3qtr, .col-1-3qtr, .col-2-3qtr, .col-3-3qtr, .col-4-3qtr, .col-5-3qtr, .col-6-3qtr, .col-7-3qtr, .col-8-3qtr, .col-9-3qtr, .col-10-3qtr, .col-11-3qtr,
.col-sm-0-1qtr, .col-sm-1-1qtr, .col-sm-2-1qtr, .col-sm-3-1qtr, .col-sm-4-1qtr, .col-sm-5-1qtr, .col-sm-6-1qtr, .col-sm-7-1qtr, .col-sm-8-1qtr, .col-sm-9-1qtr, .col-sm-10-1qtr, .col-sm-11-1qtr,
.col-sm-0-half, .col-sm-1-half, .col-sm-2-half, .col-sm-3-half, .col-sm-4-half, .col-sm-5-half, .col-sm-6-half, .col-sm-7-half, .col-sm-8-half, .col-sm-9-half, .col-sm-10-half, .col-sm-11-half,
.col-sm-0-3qtr, .col-sm-1-3qtr, .col-sm-2-3qtr, .col-sm-3-3qtr, .col-sm-4-3qtr, .col-sm-5-3qtr, .col-sm-6-3qtr, .col-sm-7-3qtr, .col-sm-8-3qtr, .col-sm-9-3qtr, .col-sm-10-3qtr, .col-sm-11-3qtr,
.col-md-0-1qtr, .col-md-1-1qtr, .col-md-2-1qtr, .col-md-3-1qtr, .col-md-4-1qtr, .col-md-5-1qtr, .col-md-6-1qtr, .col-md-7-1qtr, .col-md-8-1qtr, .col-md-9-1qtr, .col-md-10-1qtr, .col-md-11-1qtr,
.col-md-0-half, .col-md-1-half, .col-md-2-half, .col-md-3-half, .col-md-4-half, .col-md-5-half, .col-md-6-half, .col-md-7-half, .col-md-8-half, .col-md-9-half, .col-md-10-half, .col-md-11-half,
.col-md-0-3qtr, .col-md-1-3qtr, .col-md-2-3qtr, .col-md-3-3qtr, .col-md-4-3qtr, .col-md-5-3qtr, .col-md-6-3qtr, .col-md-7-3qtr, .col-md-8-3qtr, .col-md-9-3qtr, .col-md-10-3qtr, .col-md-11-3qtr,
.col-lg-0-1qtr, .col-lg-1-1qtr, .col-lg-2-1qtr, .col-lg-3-1qtr, .col-lg-4-1qtr, .col-lg-5-1qtr, .col-lg-6-1qtr, .col-lg-7-1qtr, .col-lg-8-1qtr, .col-lg-9-1qtr, .col-lg-10-1qtr, .col-lg-11-1qtr,
.col-lg-0-half, .col-lg-1-half, .col-lg-2-half, .col-lg-3-half, .col-lg-4-half, .col-lg-5-half, .col-lg-6-half, .col-lg-7-half, .col-lg-8-half, .col-lg-9-half, .col-lg-10-half, .col-lg-11-half,
.col-lg-0-3qtr, .col-lg-1-3qtr, .col-lg-2-3qtr, .col-lg-3-3qtr, .col-lg-4-3qtr, .col-lg-5-3qtr, .col-lg-6-3qtr, .col-lg-7-3qtr, .col-lg-8-3qtr, .col-lg-9-3qtr, .col-lg-10-3qtr, .col-lg-11-3qtr,
.col-xl-0-1qtr, .col-xl-1-1qtr, .col-xl-2-1qtr, .col-xl-3-1qtr, .col-xl-4-1qtr, .col-xl-5-1qtr, .col-xl-6-1qtr, .col-xl-7-1qtr, .col-xl-8-1qtr, .col-xl-9-1qtr, .col-xl-10-1qtr, .col-xl-11-1qtr,
.col-xl-0-half, .col-xl-1-half, .col-xl-2-half, .col-xl-3-half, .col-xl-4-half, .col-xl-5-half, .col-xl-6-half, .col-xl-7-half, .col-xl-8-half, .col-xl-9-half, .col-xl-10-half, .col-xl-11-half,
.col-xl-0-3qtr, .col-xl-1-3qtr, .col-xl-2-3qtr, .col-xl-3-3qtr, .col-xl-4-3qtr, .col-xl-5-3qtr, .col-xl-6-3qtr, .col-xl-7-3qtr, .col-xl-8-3qtr, .col-xl-9-3qtr, .col-xl-10-3qtr, .col-xl-11-3qtr {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-0-1qtr {
    -ms-flex: 0 0 2.083333%;
    flex: 0 0 2.083333%;
    max-width: 2.083333%;
}

.col-0-half {
    -ms-flex: 0 0 4.166667%;
    flex: 0 0 4.166667%;
    max-width: 4.166667%;
}

.col-0-3qtr {
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
}

.col-1-1qtr {
    -ms-flex: 0 0 10.416667%;
    flex: 0 0 10.416667%;
    max-width: 10.416667%;
}

.col-1-half {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
}

.col-1-3qtr {
    -ms-flex: 0 0 14.583333%;
    flex: 0 0 14.583333%;
    max-width: 14.583333%;
}

.col-2-1qtr {
    -ms-flex: 0 0 18.75%;
    flex: 0 0 18.75%;
    max-width: 18.75%;
}

.col-2-half {
    -ms-flex: 0 0 20.833333%;
    flex: 0 0 20.833333%;
    max-width: 20.833333%;
}

.col-2-3qtr {
    -ms-flex: 0 0 22.916667%;
    flex: 0 0 22.916667%;
    max-width: 22.916667%;
}

.col-3-1qtr {
    -ms-flex: 0 0 27.083333%;
    flex: 0 0 27.083333%;
    max-width: 27.083333%;
}

.col-3-half {
    -ms-flex: 0 0 29.166667%;
    flex: 0 0 29.166667%;
    max-width: 29.166667%;
}

.col-3-3qtr {
    -ms-flex: 0 0 31.25%;
    flex: 0 0 31.25%;
    max-width: 31.25%;
}

.col-4-1qtr {
    -ms-flex: 0 0 35.416667%;
    flex: 0 0 35.416667%;
    max-width: 35.416667%;
}

.col-4-half {
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
}

.col-4-3qtr {
    -ms-flex: 0 0 39.583333%;
    flex: 0 0 39.583333%;
    max-width: 39.583333%;
}

.col-5-1qtr {
    -ms-flex: 0 0 43.75%;
    flex: 0 0 43.75%;
    max-width: 43.75%;
}

.col-5-half {
    -ms-flex: 0 0 45.833333%;
    flex: 0 0 45.833333%;
    max-width: 45.833333%;
}

.col-5-3qtr {
    -ms-flex: 0 0 47.916667%;
    flex: 0 0 47.916667%;
    max-width: 47.916667%;
}

.col-6-1qtr {
    -ms-flex: 0 0 52.083333%;
    flex: 0 0 52.083333%;
    max-width: 52.083333%;
}

.col-6-half {
    -ms-flex: 0 0 54.166667%;
    flex: 0 0 54.166667%;
    max-width: 54.166667%;
}

.col-6-3qtr {
    -ms-flex: 0 0 56.25%;
    flex: 0 0 56.25%;
    max-width: 56.25%;
}

.col-7-1qtr {
    -ms-flex: 0 0 60.416667%;
    flex: 0 0 60.416667%;
    max-width: 60.416667%;
}

.col-7-half {
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
}

.col-7-3qtr {
    -ms-flex: 0 0 64.583333%;
    flex: 0 0 64.583333%;
    max-width: 64.583333%;
}

.col-8-1qtr {
    -ms-flex: 0 0 68.75%;
    flex: 0 0 68.75%;
    max-width: 68.75%;
}

.col-8-half {
    -ms-flex: 0 0 70.833333%;
    flex: 0 0 70.833333%;
    max-width: 70.833333%;
}

.col-8-3qtr {
    -ms-flex: 0 0 72.916667%;
    flex: 0 0 72.916667%;
    max-width: 72.916667%;
}

.col-9-1qtr {
    -ms-flex: 0 0 77.083333%;
    flex: 0 0 77.083333%;
    max-width: 77.083333%;
}

.col-9-half {
    -ms-flex: 0 0 79.166667%;
    flex: 0 0 79.166667%;
    max-width: 79.166667%;
}

.col-9-3qtr {
    -ms-flex: 0 0 81.25%;
    flex: 0 0 81.25%;
    max-width: 81.25%;
}

.col-10-1qtr {
    -ms-flex: 0 0 85.416667%;
    flex: 0 0 85.416667%;
    max-width: 85.416667%;
}

.col-10-half {
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
}

.col-10-3qtr {
    -ms-flex: 0 0 89.583333%;
    flex: 0 0 89.583333%;
    max-width: 89.583333%;
}

.col-11-1qtr {
    -ms-flex: 0 0 93.75%;
    flex: 0 0 93.75%;
    max-width: 93.75%;
}

.col-11-half {
    -ms-flex: 0 0 95.833333%;
    flex: 0 0 95.833333%;
    max-width: 95.833333%;
}

.col-11-3qtr {
    -ms-flex: 0 0 97.916667%;
    flex: 0 0 97.916667%;
    max-width: 97.916667%;
}

@media (min-width: 576px) {
    .col-sm-0-1qtr {
        -ms-flex: 0 0 2.083333%;
        flex: 0 0 2.083333%;
        max-width: 2.083333%;
    }

    .col-sm-0-half {
        -ms-flex: 0 0 4.166667%;
        flex: 0 0 4.166667%;
        max-width: 4.166667%;
    }

    .col-sm-0-3qtr {
        -ms-flex: 0 0 6.25%;
        flex: 0 0 6.25%;
        max-width: 6.25%;
    }

    .col-sm-1-1qtr {
        -ms-flex: 0 0 10.416667%;
        flex: 0 0 10.416667%;
        max-width: 10.416667%;
    }

    .col-sm-1-half {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }

    .col-sm-1-3qtr {
        -ms-flex: 0 0 14.583333%;
        flex: 0 0 14.583333%;
        max-width: 14.583333%;
    }

    .col-sm-2-1qtr {
        -ms-flex: 0 0 18.75%;
        flex: 0 0 18.75%;
        max-width: 18.75%;
    }

    .col-sm-2-half {
        -ms-flex: 0 0 20.833333%;
        flex: 0 0 20.833333%;
        max-width: 20.833333%;
    }

    .col-sm-2-3qtr {
        -ms-flex: 0 0 22.916667%;
        flex: 0 0 22.916667%;
        max-width: 22.916667%;
    }

    .col-sm-3-1qtr {
        -ms-flex: 0 0 27.083333%;
        flex: 0 0 27.083333%;
        max-width: 27.083333%;
    }

    .col-sm-3-half {
        -ms-flex: 0 0 29.166667%;
        flex: 0 0 29.166667%;
        max-width: 29.166667%;
    }

    .col-sm-3-3qtr {
        -ms-flex: 0 0 31.25%;
        flex: 0 0 31.25%;
        max-width: 31.25%;
    }

    .col-sm-4-1qtr {
        -ms-flex: 0 0 35.416667%;
        flex: 0 0 35.416667%;
        max-width: 35.416667%;
    }

    .col-sm-4-half {
        -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }

    .col-sm-4-3qtr {
        -ms-flex: 0 0 39.583333%;
        flex: 0 0 39.583333%;
        max-width: 39.583333%;
    }

    .col-sm-5-1qtr {
        -ms-flex: 0 0 43.75%;
        flex: 0 0 43.75%;
        max-width: 43.75%;
    }

    .col-sm-5-half {
        -ms-flex: 0 0 45.833333%;
        flex: 0 0 45.833333%;
        max-width: 45.833333%;
    }

    .col-sm-5-3qtr {
        -ms-flex: 0 0 47.916667%;
        flex: 0 0 47.916667%;
        max-width: 47.916667%;
    }

    .col-sm-6-1qtr {
        -ms-flex: 0 0 52.083333%;
        flex: 0 0 52.083333%;
        max-width: 52.083333%;
    }

    .col-sm-6-half {
        -ms-flex: 0 0 54.166667%;
        flex: 0 0 54.166667%;
        max-width: 54.166667%;
    }

    .col-sm-6-3qtr {
        -ms-flex: 0 0 56.25%;
        flex: 0 0 56.25%;
        max-width: 56.25%;
    }

    .col-sm-7-1qtr {
        -ms-flex: 0 0 60.416667%;
        flex: 0 0 60.416667%;
        max-width: 60.416667%;
    }

    .col-sm-7-half {
        -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
        max-width: 62.5%;
    }

    .col-sm-7-3qtr {
        -ms-flex: 0 0 64.583333%;
        flex: 0 0 64.583333%;
        max-width: 64.583333%;
    }

    .col-sm-8-1qtr {
        -ms-flex: 0 0 68.75%;
        flex: 0 0 68.75%;
        max-width: 68.75%;
    }

    .col-sm-8-half {
        -ms-flex: 0 0 70.833333%;
        flex: 0 0 70.833333%;
        max-width: 70.833333%;
    }

    .col-sm-8-3qtr {
        -ms-flex: 0 0 72.916667%;
        flex: 0 0 72.916667%;
        max-width: 72.916667%;
    }

    .col-sm-9-1qtr {
        -ms-flex: 0 0 77.083333%;
        flex: 0 0 77.083333%;
        max-width: 77.083333%;
    }

    .col-sm-9-half {
        -ms-flex: 0 0 79.166667%;
        flex: 0 0 79.166667%;
        max-width: 79.166667%;
    }

    .col-sm-9-3qtr {
        -ms-flex: 0 0 81.25%;
        flex: 0 0 81.25%;
        max-width: 81.25%;
    }

    .col-sm-10-1qtr {
        -ms-flex: 0 0 85.416667%;
        flex: 0 0 85.416667%;
        max-width: 85.416667%;
    }

    .col-sm-10-half {
        -ms-flex: 0 0 87.5%;
        flex: 0 0 87.5%;
        max-width: 87.5%;
    }

    .col-sm-10-3qtr {
        -ms-flex: 0 0 89.583333%;
        flex: 0 0 89.583333%;
        max-width: 89.583333%;
    }

    .col-sm-11-1qtr {
        -ms-flex: 0 0 93.75%;
        flex: 0 0 93.75%;
        max-width: 93.75%;
    }

    .col-sm-11-half {
        -ms-flex: 0 0 95.833333%;
        flex: 0 0 95.833333%;
        max-width: 95.833333%;
    }

    .col-sm-11-3qtr {
        -ms-flex: 0 0 97.916667%;
        flex: 0 0 97.916667%;
        max-width: 97.916667%;
    }
}

@media (min-width: 768px) {
    .col-md-0-1qtr {
        -ms-flex: 0 0 2.083333%;
        flex: 0 0 2.083333%;
        max-width: 2.083333%;
    }

    .col-md-0-half {
        -ms-flex: 0 0 4.166667%;
        flex: 0 0 4.166667%;
        max-width: 4.166667%;
    }

    .col-md-0-3qtr {
        -ms-flex: 0 0 6.25%;
        flex: 0 0 6.25%;
        max-width: 6.25%;
    }

    .col-md-1-1qtr {
        -ms-flex: 0 0 10.416667%;
        flex: 0 0 10.416667%;
        max-width: 10.416667%;
    }

    .col-md-1-half {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }

    .col-md-1-3qtr {
        -ms-flex: 0 0 14.583333%;
        flex: 0 0 14.583333%;
        max-width: 14.583333%;
    }

    .col-md-2-1qtr {
        -ms-flex: 0 0 18.75%;
        flex: 0 0 18.75%;
        max-width: 18.75%;
    }

    .col-md-2-half {
        -ms-flex: 0 0 20.833333%;
        flex: 0 0 20.833333%;
        max-width: 20.833333%;
    }

    .col-md-2-3qtr {
        -ms-flex: 0 0 22.916667%;
        flex: 0 0 22.916667%;
        max-width: 22.916667%;
    }

    .col-md-3-1qtr {
        -ms-flex: 0 0 27.083333%;
        flex: 0 0 27.083333%;
        max-width: 27.083333%;
    }

    .col-md-3-half {
        -ms-flex: 0 0 29.166667%;
        flex: 0 0 29.166667%;
        max-width: 29.166667%;
    }

    .col-md-3-3qtr {
        -ms-flex: 0 0 31.25%;
        flex: 0 0 31.25%;
        max-width: 31.25%;
    }

    .col-md-4-1qtr {
        -ms-flex: 0 0 35.416667%;
        flex: 0 0 35.416667%;
        max-width: 35.416667%;
    }

    .col-md-4-half {
        -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }

    .col-md-4-3qtr {
        -ms-flex: 0 0 39.583333%;
        flex: 0 0 39.583333%;
        max-width: 39.583333%;
    }

    .col-md-5-1qtr {
        -ms-flex: 0 0 43.75%;
        flex: 0 0 43.75%;
        max-width: 43.75%;
    }

    .col-md-5-half {
        -ms-flex: 0 0 45.833333%;
        flex: 0 0 45.833333%;
        max-width: 45.833333%;
    }

    .col-md-5-3qtr {
        -ms-flex: 0 0 47.916667%;
        flex: 0 0 47.916667%;
        max-width: 47.916667%;
    }

    .col-md-6-1qtr {
        -ms-flex: 0 0 52.083333%;
        flex: 0 0 52.083333%;
        max-width: 52.083333%;
    }

    .col-md-6-half {
        -ms-flex: 0 0 54.166667%;
        flex: 0 0 54.166667%;
        max-width: 54.166667%;
    }

    .col-md-6-3qtr {
        -ms-flex: 0 0 56.25%;
        flex: 0 0 56.25%;
        max-width: 56.25%;
    }

    .col-md-7-1qtr {
        -ms-flex: 0 0 60.416667%;
        flex: 0 0 60.416667%;
        max-width: 60.416667%;
    }

    .col-md-7-half {
        -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
        max-width: 62.5%;
    }

    .col-md-7-3qtr {
        -ms-flex: 0 0 64.583333%;
        flex: 0 0 64.583333%;
        max-width: 64.583333%;
    }

    .col-md-8-1qtr {
        -ms-flex: 0 0 68.75%;
        flex: 0 0 68.75%;
        max-width: 68.75%;
    }

    .col-md-8-half {
        -ms-flex: 0 0 70.833333%;
        flex: 0 0 70.833333%;
        max-width: 70.833333%;
    }

    .col-md-8-3qtr {
        -ms-flex: 0 0 72.916667%;
        flex: 0 0 72.916667%;
        max-width: 72.916667%;
    }

    .col-md-9-1qtr {
        -ms-flex: 0 0 77.083333%;
        flex: 0 0 77.083333%;
        max-width: 77.083333%;
    }

    .col-md-9-half {
        -ms-flex: 0 0 79.166667%;
        flex: 0 0 79.166667%;
        max-width: 79.166667%;
    }

    .col-md-9-3qtr {
        -ms-flex: 0 0 81.25%;
        flex: 0 0 81.25%;
        max-width: 81.25%;
    }

    .col-md-10-1qtr {
        -ms-flex: 0 0 85.416667%;
        flex: 0 0 85.416667%;
        max-width: 85.416667%;
    }

    .col-md-10-half {
        -ms-flex: 0 0 87.5%;
        flex: 0 0 87.5%;
        max-width: 87.5%;
    }

    .col-md-10-3qtr {
        -ms-flex: 0 0 89.583333%;
        flex: 0 0 89.583333%;
        max-width: 89.583333%;
    }

    .col-md-11-1qtr {
        -ms-flex: 0 0 93.75%;
        flex: 0 0 93.75%;
        max-width: 93.75%;
    }

    .col-md-11-half {
        -ms-flex: 0 0 95.833333%;
        flex: 0 0 95.833333%;
        max-width: 95.833333%;
    }

    .col-md-11-3qtr {
        -ms-flex: 0 0 97.916667%;
        flex: 0 0 97.916667%;
        max-width: 97.916667%;
    }
}

@media (min-width: 992px) {
    .col-lg-0-1qtr {
        -ms-flex: 0 0 2.083333%;
        flex: 0 0 2.083333%;
        max-width: 2.083333%;
    }

    .col-lg-0-half {
        -ms-flex: 0 0 4.166667%;
        flex: 0 0 4.166667%;
        max-width: 4.166667%;
    }

    .col-lg-0-3qtr {
        -ms-flex: 0 0 6.25%;
        flex: 0 0 6.25%;
        max-width: 6.25%;
    }

    .col-lg-1-1qtr {
        -ms-flex: 0 0 10.416667%;
        flex: 0 0 10.416667%;
        max-width: 10.416667%;
    }

    .col-lg-1-half {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }

    .col-lg-1-3qtr {
        -ms-flex: 0 0 14.583333%;
        flex: 0 0 14.583333%;
        max-width: 14.583333%;
    }

    .col-lg-2-1qtr {
        -ms-flex: 0 0 18.75%;
        flex: 0 0 18.75%;
        max-width: 18.75%;
    }

    .col-lg-2-half {
        -ms-flex: 0 0 20.833333%;
        flex: 0 0 20.833333%;
        max-width: 20.833333%;
    }

    .col-lg-2-3qtr {
        -ms-flex: 0 0 22.916667%;
        flex: 0 0 22.916667%;
        max-width: 22.916667%;
    }

    .col-lg-3-1qtr {
        -ms-flex: 0 0 27.083333%;
        flex: 0 0 27.083333%;
        max-width: 27.083333%;
    }

    .col-lg-3-half {
        -ms-flex: 0 0 29.166667%;
        flex: 0 0 29.166667%;
        max-width: 29.166667%;
    }

    .col-lg-3-3qtr {
        -ms-flex: 0 0 31.25%;
        flex: 0 0 31.25%;
        max-width: 31.25%;
    }

    .col-lg-4-1qtr {
        -ms-flex: 0 0 35.416667%;
        flex: 0 0 35.416667%;
        max-width: 35.416667%;
    }

    .col-lg-4-half {
        -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }

    .col-lg-4-3qtr {
        -ms-flex: 0 0 39.583333%;
        flex: 0 0 39.583333%;
        max-width: 39.583333%;
    }

    .col-lg-5-1qtr {
        -ms-flex: 0 0 43.75%;
        flex: 0 0 43.75%;
        max-width: 43.75%;
    }

    .col-lg-5-half {
        -ms-flex: 0 0 45.833333%;
        flex: 0 0 45.833333%;
        max-width: 45.833333%;
    }

    .col-lg-5-3qtr {
        -ms-flex: 0 0 47.916667%;
        flex: 0 0 47.916667%;
        max-width: 47.916667%;
    }

    .col-lg-6-1qtr {
        -ms-flex: 0 0 52.083333%;
        flex: 0 0 52.083333%;
        max-width: 52.083333%;
    }

    .col-lg-6-half {
        -ms-flex: 0 0 54.166667%;
        flex: 0 0 54.166667%;
        max-width: 54.166667%;
    }

    .col-lg-6-3qtr {
        -ms-flex: 0 0 56.25%;
        flex: 0 0 56.25%;
        max-width: 56.25%;
    }

    .col-lg-7-1qtr {
        -ms-flex: 0 0 60.416667%;
        flex: 0 0 60.416667%;
        max-width: 60.416667%;
    }

    .col-lg-7-half {
        -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
        max-width: 62.5%;
    }

    .col-lg-7-3qtr {
        -ms-flex: 0 0 64.583333%;
        flex: 0 0 64.583333%;
        max-width: 64.583333%;
    }

    .col-lg-8-1qtr {
        -ms-flex: 0 0 68.75%;
        flex: 0 0 68.75%;
        max-width: 68.75%;
    }

    .col-lg-8-half {
        -ms-flex: 0 0 70.833333%;
        flex: 0 0 70.833333%;
        max-width: 70.833333%;
    }

    .col-lg-8-3qtr {
        -ms-flex: 0 0 72.916667%;
        flex: 0 0 72.916667%;
        max-width: 72.916667%;
    }

    .col-lg-9-1qtr {
        -ms-flex: 0 0 77.083333%;
        flex: 0 0 77.083333%;
        max-width: 77.083333%;
    }

    .col-lg-9-half {
        -ms-flex: 0 0 79.166667%;
        flex: 0 0 79.166667%;
        max-width: 79.166667%;
    }

    .col-lg-9-3qtr {
        -ms-flex: 0 0 81.25%;
        flex: 0 0 81.25%;
        max-width: 81.25%;
    }

    .col-lg-10-1qtr {
        -ms-flex: 0 0 85.416667%;
        flex: 0 0 85.416667%;
        max-width: 85.416667%;
    }

    .col-lg-10-half {
        -ms-flex: 0 0 87.5%;
        flex: 0 0 87.5%;
        max-width: 87.5%;
    }

    .col-lg-10-3qtr {
        -ms-flex: 0 0 89.583333%;
        flex: 0 0 89.583333%;
        max-width: 89.583333%;
    }

    .col-lg-11-1qtr {
        -ms-flex: 0 0 93.75%;
        flex: 0 0 93.75%;
        max-width: 93.75%;
    }

    .col-lg-11-half {
        -ms-flex: 0 0 95.833333%;
        flex: 0 0 95.833333%;
        max-width: 95.833333%;
    }

    .col-lg-11-3qtr {
        -ms-flex: 0 0 97.916667%;
        flex: 0 0 97.916667%;
        max-width: 97.916667%;
    }
}

@media (min-width: 1200px) {
    .col-xl-0-1qtr {
        -ms-flex: 0 0 2.083333%;
        flex: 0 0 2.083333%;
        max-width: 2.083333%;
    }

    .col-xl-0-half {
        -ms-flex: 0 0 4.166667%;
        flex: 0 0 4.166667%;
        max-width: 4.166667%;
    }

    .col-xl-0-3qtr {
        -ms-flex: 0 0 6.25%;
        flex: 0 0 6.25%;
        max-width: 6.25%;
    }

    .col-xl-1-1qtr {
        -ms-flex: 0 0 10.416667%;
        flex: 0 0 10.416667%;
        max-width: 10.416667%;
    }

    .col-xl-1-half {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }

    .col-xl-1-3qtr {
        -ms-flex: 0 0 14.583333%;
        flex: 0 0 14.583333%;
        max-width: 14.583333%;
    }

    .col-xl-2-1qtr {
        -ms-flex: 0 0 18.75%;
        flex: 0 0 18.75%;
        max-width: 18.75%;
    }

    .col-xl-2-half {
        -ms-flex: 0 0 20.833333%;
        flex: 0 0 20.833333%;
        max-width: 20.833333%;
    }

    .col-xl-2-3qtr {
        -ms-flex: 0 0 22.916667%;
        flex: 0 0 22.916667%;
        max-width: 22.916667%;
    }

    .col-xl-3-1qtr {
        -ms-flex: 0 0 27.083333%;
        flex: 0 0 27.083333%;
        max-width: 27.083333%;
    }

    .col-xl-3-half {
        -ms-flex: 0 0 29.166667%;
        flex: 0 0 29.166667%;
        max-width: 29.166667%;
    }

    .col-xl-3-3qtr {
        -ms-flex: 0 0 31.25%;
        flex: 0 0 31.25%;
        max-width: 31.25%;
    }

    .col-xl-4-1qtr {
        -ms-flex: 0 0 35.416667%;
        flex: 0 0 35.416667%;
        max-width: 35.416667%;
    }

    .col-xl-4-half {
        -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }

    .col-xl-4-3qtr {
        -ms-flex: 0 0 39.583333%;
        flex: 0 0 39.583333%;
        max-width: 39.583333%;
    }

    .col-xl-5-1qtr {
        -ms-flex: 0 0 43.75%;
        flex: 0 0 43.75%;
        max-width: 43.75%;
    }

    .col-xl-5-half {
        -ms-flex: 0 0 45.833333%;
        flex: 0 0 45.833333%;
        max-width: 45.833333%;
    }

    .col-xl-5-3qtr {
        -ms-flex: 0 0 47.916667%;
        flex: 0 0 47.916667%;
        max-width: 47.916667%;
    }

    .col-xl-6-1qtr {
        -ms-flex: 0 0 52.083333%;
        flex: 0 0 52.083333%;
        max-width: 52.083333%;
    }

    .col-xl-6-half {
        -ms-flex: 0 0 54.166667%;
        flex: 0 0 54.166667%;
        max-width: 54.166667%;
    }

    .col-xl-6-3qtr {
        -ms-flex: 0 0 56.25%;
        flex: 0 0 56.25%;
        max-width: 56.25%;
    }

    .col-xl-7-1qtr {
        -ms-flex: 0 0 60.416667%;
        flex: 0 0 60.416667%;
        max-width: 60.416667%;
    }

    .col-xl-7-half {
        -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
        max-width: 62.5%;
    }

    .col-xl-7-3qtr {
        -ms-flex: 0 0 64.583333%;
        flex: 0 0 64.583333%;
        max-width: 64.583333%;
    }

    .col-xl-8-1qtr {
        -ms-flex: 0 0 68.75%;
        flex: 0 0 68.75%;
        max-width: 68.75%;
    }

    .col-xl-8-half {
        -ms-flex: 0 0 70.833333%;
        flex: 0 0 70.833333%;
        max-width: 70.833333%;
    }

    .col-xl-8-3qtr {
        -ms-flex: 0 0 72.916667%;
        flex: 0 0 72.916667%;
        max-width: 72.916667%;
    }

    .col-xl-9-1qtr {
        -ms-flex: 0 0 77.083333%;
        flex: 0 0 77.083333%;
        max-width: 77.083333%;
    }

    .col-xl-9-half {
        -ms-flex: 0 0 79.166667%;
        flex: 0 0 79.166667%;
        max-width: 79.166667%;
    }

    .col-xl-9-3qtr {
        -ms-flex: 0 0 81.25%;
        flex: 0 0 81.25%;
        max-width: 81.25%;
    }

    .col-xl-10-1qtr {
        -ms-flex: 0 0 85.416667%;
        flex: 0 0 85.416667%;
        max-width: 85.416667%;
    }

    .col-xl-10-half {
        -ms-flex: 0 0 87.5%;
        flex: 0 0 87.5%;
        max-width: 87.5%;
    }

    .col-xl-10-3qtr {
        -ms-flex: 0 0 89.583333%;
        flex: 0 0 89.583333%;
        max-width: 89.583333%;
    }

    .col-xl-11-1qtr {
        -ms-flex: 0 0 93.75%;
        flex: 0 0 93.75%;
        max-width: 93.75%;
    }

    .col-xl-11-half {
        -ms-flex: 0 0 95.833333%;
        flex: 0 0 95.833333%;
        max-width: 95.833333%;
    }

    .col-xl-11-3qtr {
        -ms-flex: 0 0 97.916667%;
        flex: 0 0 97.916667%;
        max-width: 97.916667%;
    }
}
