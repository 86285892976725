@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Poppins:wght@300;400;500;600;700&display=swap');

$global_transition:all 0.1s linear;
$global_border_radius:2px;

// Custom
$c-btn-blue:#438efd;
$c-btn-blue-hover:#1371fd;
$c-light-blue:#f2f7fb;
$c-light-blue-2:#ecf7ff;
$c-light-blue-3:#f9fcff;
$c-dark-blue:#97b3c6;

$c-light-silver:#dfe8ee;
$c-light-silver-2:#d3dadf;
$c-gold:#beb52a;
$c-yellow:#f5e700;

$c-blue:#438efd;

$c-btn-red:#e22228;
$c-btn-red-hover:#354551;
$c-header-btn:#c2cad0;
$c-header-btn-hover:#cfd9e0;

//----

$c-btn-green:#8ea17e;
$c-btn-green-hover:#718c59;
$c-black:#454842;
$c-silver-lighter:#f6f6f6;
$c-silver-light:#e8e8e8;
$c-silver:#dbdbdb;
$c-silver-dark:#bababa;
$c-green-light:#d1d5cd;
$c-green:#8da07c;
$c-green-black:#4c5148;
$c-orange:#fea620;
$c-orange-dark:#573603;
$c-red:#fa3157;
$c-red-light:#ffeeee;
$c-red-dark:#d12041;




// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #555454 !default;

$primary: #555050 !default;


// Body

$body-bg: $white !default;
$body-color: $primary !default;


// Fonts

$font-family-sans-serif: 'Poppins', sans-serif,-apple-system;
$font-family-header: 'Montserrat', sans-serif,-apple-system;

$font-size-base: 1rem !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          600 !default;
$font-weight-bold:            700 !default;
$font-weight-extrabold:       900 !default;
$font-weight-bolder:          bolder !default;

$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 1.5 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1.125 !default;
$h6-font-size: $font-size-base * 0.875 !default;

$headings-color: $black !default;

$text-muted: $gray-500 !default;


// Breadcrumbs

$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: 2rem !default;

$breadcrumb-bg: transparent !default;
$breadcrumb-divider-color: $gray-300 !default;


// Navs

$nav-link-padding-y: .8rem !default;


// Navbar

$navbar-padding-y: 0 !default;
$navbar-padding-x: 0 !default;

$navbar-nav-link-padding-x: 20px !default;

$navbar-toggler-padding-y: .4rem !default;
$navbar-light-toggler-border-color: transparent !default;


// Alerts

$alert-bg-level: -12 !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// POVODNY GRID BREAKPOINTS
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

//ROZSIRENY GRID BREAKPOINTS
//$grid-breakpoints: (
//    xs: 0,
//    sm: 768px,
//    md: 992px,
//    lg: 1200px,
//    xl: 1490px,
//) !default;



// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// POVODNY CONTAINER MAX-WIDTHS
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1170px
) !default;

//ROZSIRENY CONTAINER MAX-WIDTHS
//$container-max-widths: (
//    sm: 720px,
//    md: 960px,
//    lg: 1140px,
//    xl: 1430px
//) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;
