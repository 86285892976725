// Imports
// --------------------------------------------------
@import "../resources";
// --------------------------------------------------

.algolia-autocomplete {
    width: 100%;
}
.algolia-autocomplete .aa-input, .algolia-autocomplete .aa-hint {
    width: 100%;
}
.algolia-autocomplete .aa-hint {
    color: #999;
}
.algolia-autocomplete .aa-dropdown-menu {
    width: 100%;
    background-color: #fff;
    border: 1px solid #999;
    border-top: none;
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
    cursor: pointer;
    padding: 0;

    p {
        margin: 0;
    }
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor {
    background-color: #B2D7FF;
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion em {
    font-weight: bold;
    font-style: normal;
}
.algolia-autocomplete {

}
#algolia-autocomplete-listbox-0 {
    position:absolute;
    top:100%;
    background:$white;
    border:1px solid $c-light-silver-2;
    width:100%;
    margin-top:1px;
    border-radius:$global_border_radius;

    @include media-breakpoint-down(md) {
        height:25rem;
        overflow:auto;
    }
}

.search-item-wrap {
    display:flex;
    flex-direction:row;
    padding: 1em;

    border-bottom:1px solid $c-light-silver;

    .img {
        width:100%;
        max-width:5em;
        margin-right:1em;

        img {
            display:block;
            width:100%;
            height:auto;
            border-radius:$global_border_radius;
        }
    }
    .content {
        flex:1;
        font-size:0.875em;
        * {
            line-height:1;
        }
        h6 {
            margin-bottom:0.2em;
        }
        .code {
            color:$c-silver-dark;
            font-size:0.875em;
        }
        .price-wrap {
            display: flex;
            flex-direction: row;
            margin-top:0.25em;
            align-items: center;
        }
        .price {
            display:inline-block;
            font-weight:$font-weight-extrabold;
            color:$c-red;
            margin-right:0.25em;
        }
        .tier-price {
            display:inline-block;
            padding:0.35em 0.5em;
            background:$c-orange;
            color:$c-orange-dark;
            font-weight:$font-weight-bold;
            margin-left:auto;
            border-radius:$global-border_radius;
        }
        .strike {
            display:inline-block;
            margin-right:0.25em;
            color:$c-silver-dark;
        }
    }
    &:last-child {
        border-bottom:none;
    }
}
